import React from 'react';
import styles from './PictureInfo.module.scss';
import Link from 'gatsby-link'

export class PhotoInfo extends React.Component {
    hasInfo = false;

    constructor(props) {
        super(props);
        this.hasInfo = (
            props.imageData && (
                props.imageData.shortName ||
                props.imageData.date ||
                props.imageData.locationName ||
                props.imageData.description
            )
        );
    }

    shouldLinkToCategory = () => {
        return this.props.linkToCategory !== undefined ? this.props.linkToCategory : false;
    }

    toggleInfo(id) {
        document.getElementById(id).classList.toggle(styles.boxInfoActive);
        
    }

    static hideAllInfoBox (event) {
        if(!event.target.classList.contains(styles.roundInfo)) {
            document.querySelectorAll(`.${styles.boxInfoActive}`).forEach(item => item.classList.remove(styles.boxInfoActive));
        }
    }

    componentDidMount() {
        document.body.addEventListener("click", PhotoInfo.hideAllInfoBox);
    }

    render() {
        if(this.hasInfo) {
            return (
                <div>
                    <div className={styles.roundInfo} onClick={(event) => {this.toggleInfo(`${styles.boxInfo}_${this.props.imageIndex}`);event.preventDefault();return false;}} onKeyPress={(event) => {this.toggleInfo(`${styles.boxInfo}_${this.props.imageIndex}`);event.preventDefault();return false;}} role="button" tabIndex="0">i</div>
                    <Link to={this.props.linkToImagePage} className={styles.roundPlus} role="button" tabIndex="0"><svg width="14" height="14" viewBox="0 0 512 512" fill="white" style={{position: "absolute", left: "4px", top: "4px"}}><path d="M290 0l56.389 56.389L257.778 145l48.333 48.333 88.611-88.611 56.389 56.389V0zM145 257.778l-88.611 88.611L0 290v161.111h161.111l-56.389-56.389 88.611-88.611zm161.111 0l-48.333 48.333 88.611 88.611L290 451.111h161.111V290l-56.389 56.389zM161.111 0H0v161.111l56.389-56.389L145 193.333 193.333 145l-88.611-88.611z"></path></svg></Link>
                    <div className={styles.boxInfo} id={`${styles.boxInfo}_${this.props.imageIndex}`}>
                        <span>
                            <div className={styles.boxInfoDateContainer}>{(this.props.imageData.date) ? <span className={styles.boxInfoDate}>{this.props.imageData.date}<br /></span> : null }</div>
                            {(this.props.imageData.shortName) ? <span className={styles.boxInfoName}>{this.props.imageData.shortName}<br /></span> : null }
                            {(this.props.imageData.locationName) ? <span className={styles.boxInfoLocation}>{this.props.imageData.locationName}<br /></span> : null }
                            {(this.props.imageData.description) ? <span className={styles.boxInfoDescription}>{this.props.imageData.description}<br /></span> : null }
                        </span>
                    </div>
                    {(this.shouldLinkToCategory() && this.props.imageData.categorySlug && this.props.imageData.categoryName) ? <Link to={this.props.imageData.categorySlug} className={styles.categoryLink}><span>+&nbsp;</span>{this.props.imageData.categoryName}</Link> : null }
                </div>
            )
        } else if(this.shouldLinkToCategory() && this.props.imageData.categorySlug && this.props.imageData.categoryName) {
            return (
                <div>
                    <Link to={this.props.imageData.categorySlug} className={styles.categoryLink + " " + styles.categoryLinkNoRoundInfo}>+ {this.props.imageData.categoryName}</Link>
                </div>
            )
        }
        return null;
    }
}