import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/Layout';
import Plx from 'react-plx';
import { PhotoGrid } from '../components/PhotoGrid';
import { Section } from '../components/Section';
import { ScrollHint } from "../components/ScrollHint";
import Seo from "../components/Seo"

const MAIN_COVERS = {
    cover_1920x1080: "cuba-paredon-1920x1080.jpg",
    cover_1920x1440: "cuba-paredon-1920x1440.jpg",
    cover_1080x1920: "atardecer-caba-1080x1080.jpg",
    cover_1080x2266: "atardecer-caba-1080x1080.jpg",
    cover_1080x1440: "atardecer-caba-1080x1080.jpg",
    cover_1080x1080: "cuba-paredon-1080x1080.jpg"
}

class IndexPage extends React.Component {
    allCovers;
    constructor(props){
        super(props);
        this.allCovers = {
            cover_1920x1080: null,
            cover_1920x1440: null,
            cover_1080x1920: null,
            cover_1080x2266: null,
            cover_1080x1440: null,
            cover_1080x1080: null
        };

        this.props.data.allImageSharp.edges.forEach((cover) => {
            const COVER_KEY = `cover_${cover.node.original.width}x${cover.node.original.height}`;
            if(this.allCovers[COVER_KEY] === null) {
                    this.allCovers[COVER_KEY] = [cover.node];
            } else if(this.allCovers[COVER_KEY] instanceof Array) {
                if(cover.node.fluid.originalName === MAIN_COVERS[COVER_KEY]) {
                    this.allCovers[COVER_KEY].unshift(cover.node);
                } else {
                    this.allCovers[COVER_KEY].push(cover.node);
                }
            } else {
                console.error(COVER_KEY + " is not a valid size for covers.");
            }
        });

    }

    componentDidMount() {
        //console.log("DIDMOUNT")
    }

    componentDidUpdate() {
        //console.log("UPDATE")
    }

    render() {
        let covers = {};

        Object.keys(this.allCovers).forEach(key => {
            if(this.allCovers[key] instanceof Array) {
                covers[key] = this.allCovers[key][(typeof document !== 'undefined') ? Math.floor(Math.random()*this.allCovers[key].length) : 0];
            }
            else(console.error("There was a problem with cover: " + key + ". Probably there is no cover of this size"));
        });

        const getHeroDivStyles = () => {
            let styles = {
                height:'96vh',
                position:'relative',
                overflow:'hidden',
                border:'1vh solid white',
                margin:'1vh',
                backgroundSize: "cover",
                WebkitBoxShadow: '5px 5px 4px 0px rgba(0,0,0,0.75)',
                MozBoxShadow: '5px 5px 4px 0px rgba(0,0,0,0.75)',
                boxShadow: '5px 5px 4px 0px rgba(0,0,0,0.75)',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            };

            if(typeof document === 'undefined') {
                styles.backgroundImage = `url('data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAeAB4DASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAABAIFAQMI/8QAJhAAAgEDAgQHAAAAAAAAAAAAAQIAAwQREiEFIjFBExQjQlGBsf/EABYBAQEBAAAAAAAAAAAAAAAAAAMCBP/EABsRAAMAAwEBAAAAAAAAAAAAAAABAgMSIREi/9oADAMBAAIRAxEAPwDnzH7JMCRtEigGXIYA95uNswK6kYAjJOJsmGwEwa5Ak95aPw7w7YVmI0nEHXUIQtPmb3HtHmdH0HJ9LgejVI6RfnGZAjHl6fUAg5ZgtgwJtpDeFxaXSJklQRjZTuBD3N1qf0wEHwu0LTOxkH6yqyNojXp//9k=')`;
                return styles;
            }


            let aspectRatio = 1;

            if(typeof document !== 'undefined') {
                const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
                const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
                aspectRatio = w / h;
            }

            const base64 = Object.keys(covers)
                .map((cover) => {
                    return {
                        base64: covers[cover].fluid.base64,
                        aspectRatio: covers[cover].fluid.aspectRatio
                    };
                })
                .sort((a,b) => {
                    return a.aspectRatio - b.aspectRatio;
                })
                .reduce((prev,cover)=>{
                    if(!prev) return cover;
                    if(cover.aspectRatio <= aspectRatio) {
                        return cover;
                    } else {
                        return prev;
                    }
                }).base64;

            if(base64) styles.backgroundImage = `url(${base64})`;

            return styles;
        };

        const getCoverSource = ({ srcType, originalString, pixelDensity=1 }) => {
            //if(typeof document !== 'undefined') return originalString;
            if (srcType === "srcSet") {
                const regex = /^(\/.*-([0-9]+x[0-9]+).(webp|jpg)) ([0-9]+)w/gm;
                //const subst = `/get-rnd-cover?dimentions=$1&type=$2&width=$3&rd=true $3w`;
                let matches;
                let modifiedParts = [];
                while((matches = regex.exec(originalString)) !== null) {
                    if(typeof document !== 'undefined') {
                        modifiedParts.push(`${matches[1]} ${matches[4] * pixelDensity}w`);
                    } else {
                        modifiedParts.push(`/get-rnd-cover?dimentions=${matches[2]}&type=${matches[3]}&width=${matches[4]}&rd=true ${matches[4] * pixelDensity}w`);
                    }
                }
                
                //return originalString.replace(regex, subst);
                return modifiedParts.join(",\n")
            }

            if (srcType === "src") {
                const regex = /^\/.*-([0-9]+x[0-9]+).(webp|jpg)$/g;
                const subst = `/get-rnd-cover?dimentions=$1&type=$2&rd=true`;
                if(typeof document !== 'undefined') {
                    return originalString;
                } else {
                    return originalString.replace(regex, subst);
                }
                
            }

            throw(new Error("'" + srcType + "' is not a supported srcType."));
        }

        return (
            <Layout location={this.props.location}>
                <Seo image={covers.cover_1920x1080.fluid.originalImg} />
                <div>
                    <div
                        className={"heroPicture"}
                        style={getHeroDivStyles()}
                    >
                        <picture>
                            <source media="(min-aspect-ratio: 160/91) and ((-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi))" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1920x1080.fluid.srcSetWebp, pixelDensity:2})} type={"image/webp"}/>
                            <source media="(min-aspect-ratio: 160/91) and ((-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi))" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1920x1080.fluid.srcSet, pixelDensity:2})} type={"image/jpeg"}/>
                            <source media="(min-aspect-ratio: 40/30) and ((-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi))" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1920x1440.fluid.srcSetWebp, pixelDensity:2})} type={"image/webp"}/>
                            <source media="(min-aspect-ratio: 40/30) and ((-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi))" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1920x1440.fluid.srcSet, pixelDensity:2})} type={"image/jpeg"}/>
                            <source media="(min-aspect-ratio: 1/1) and ((-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi))" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1080x1080.fluid.srcSetWebp, pixelDensity:2})} type={"image/webp"}/>
                            <source media="(min-aspect-ratio: 1/1) and ((-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi))" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1080x1080.fluid.srcSet, pixelDensity:2})} type={"image/jpeg"}/>
                            <source media="(min-aspect-ratio: 30/40) and ((-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi))" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1080x1440.fluid.srcSetWebp, pixelDensity:2})} type={"image/webp"}/>
                            <source media="(min-aspect-ratio: 30/40) and ((-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi))" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1080x1440.fluid.srcSet, pixelDensity:2})} type={"image/jpeg"}/>
                            <source media="(min-aspect-ratio: 9/16) and ((-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi))" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1080x1920.fluid.srcSetWebp, pixelDensity:2})} type={"image/webp"}/>
                            <source media="(min-aspect-ratio: 9/16) and ((-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi))" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1080x1920.fluid.srcSet, pixelDensity:2})} type={"image/jpeg"}/>

                            <source media="(min-aspect-ratio: 160/91)" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1920x1080.fluid.srcSetWebp})} type={"image/webp"}/>
                            <source media="(min-aspect-ratio: 160/91)" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1920x1080.fluid.srcSet})} type={"image/jpeg"}/>
                            <source media="(min-aspect-ratio: 40/30)" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1920x1440.fluid.srcSetWebp})} type={"image/webp"}/>
                            <source media="(min-aspect-ratio: 40/30)" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1920x1440.fluid.srcSet})} type={"image/jpeg"}/>
                            <source media="(min-aspect-ratio: 1/1)" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1080x1080.fluid.srcSetWebp})} type={"image/webp"}/>
                            <source media="(min-aspect-ratio: 1/1)" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1080x1080.fluid.srcSet})} type={"image/jpeg"}/>
                            <source media="(min-aspect-ratio: 30/40)" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1080x1440.fluid.srcSetWebp})} type={"image/webp"}/>
                            <source media="(min-aspect-ratio: 30/40)" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1080x1440.fluid.srcSet})} type={"image/jpeg"}/>
                            <source media="(min-aspect-ratio: 9/16)" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1080x1920.fluid.srcSetWebp})} type={"image/webp"}/>
                            <source media="(min-aspect-ratio: 9/16)" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1080x1920.fluid.srcSet})} type={"image/jpeg"}/>
                            
                            <source media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1080x2266.fluid.srcSetWebp, pixelDensity:2})} type={"image/webp"}/>
                            <source media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)" srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1080x2266.fluid.srcSet, pixelDensity:2})} type={"image/jpeg"}/>

                            <source srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1080x2266.fluid.srcSetWebp})} type={"image/webp"}/>
                            <source srcSet={getCoverSource({srcType: "srcSet", originalString:covers.cover_1080x2266.fluid.srcSet})} type={"image/jpeg"}/>
                            <img src={getCoverSource({srcType: "src", originalString:covers.cover_1080x1080.fluid.originalImg})} style={{position:'absolute', top:0, left:0, bottom:0, right:0, margin:'auto', width:'100vw'}} alt={''}/>
                        </picture>
                        <Plx
                            style={{
                                position: "absolute",
                                bottom: "10vh",
                                left: 0,
                                width: "100%"
                            }}
                            parallaxData={[
                                {
                                    start: 0,
                                    duration: 300,
                                    name: 'initStateHeader',
                                    properties: [
                                        {
                                            startValue: 10,
                                            endValue: 60,
                                            property: 'bottom',
                                            unit:'vh'
                                        },
                                        {
                                            startValue: 1,
                                            endValue: 0,
                                            property: 'opacity'
                                        }
                                    ]
                                }
                            ]}
                        >
                            <h1><span style={{
                                color: "white",
                                fontSize: "6vw",
                                fontWeight: "900",
                                letterSpacing: "-1px",
                                background: "rgba(0, 0, 0, 0.5)",
                                padding: "0 1vw 0 1vw",
                                lineHeight: "7vw"
                            }}>
                            GABRIEL JÜRGENS<span style={{padding:"0 1vw"}} /><br/><span style={{padding:"0 1vw"}} />fotografía
                        </span></h1>
                        </Plx>
                        <Plx
                            style={{

                            }}
                            parallaxData={[
                                {
                                    start: 0,
                                    duration: 50,
                                    name: 'initScroll',
                                    properties: [
                                        {
                                            startValue: 1,
                                            endValue: 0,
                                            property: 'opacity'
                                        }
                                    ]
                                }
                            ]}
                        >
                            <ScrollHint/>
                        </Plx>
                    </div>
                    <Section title={"En Foco:"} useH2={true}/>
                    <PhotoGrid data={this.props.data} linkToCategory={true} spotlightMode={true}/>
                </div>
            </Layout>

        )
    }

};

export default IndexPage;

export const HomeQuery = graphql`
    query HomePage($maxPictures: Int, $category: String, $type: String, $onlyInSpotlight: Boolean, $photoGridSortOrder: [SortOrderEnum]) {
        allImageSharp(filter: {fields: {data: {sourceInstanceName: {eq: "covers"}}}}) {
            edges {
                node {
                    fluid(quality: 80) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                    }
                    original {
                        width
                        height
                    }
                }
            }
        }
        appPhotoGridImages: allImageSharp(limit: $maxPictures, filter: {fields: {data: {sourceInstanceName: {eq: "pictures"}, category: {eq: $category}, type: {eq: $type}, inSpotlight: {eq: $onlyInSpotlight}}}}, sort: {fields: [fields___data___spotlightOrder], order: $photoGridSortOrder}) {
            edges {
                node {
                    ...PhotoGrid
                }
            }
        }
    }
`;