import React from 'react';
import { graphql } from "gatsby"
import styles from './PhotoGrid.module.scss';
import { PhotoInfo } from './PictureInfo';

export class PhotoGrid extends React.Component {

    static DEFAULT_FRAME_BORDER = 8;
    static DEFAULT_EXPECTED_ROW_HEIGHT = 400;

    shouldLinkToCategory = () => {
        return this.props.linkToCategory !== undefined ? this.props.linkToCategory : false;
    }

    getFrameBorder = () => {
        return this.props.frameBorder !== undefined ? this.props.frameBorder : PhotoGrid.DEFAULT_FRAME_BORDER;
    };

    getExpectedRowHeight = () => {
        return this.props.rowHeight !== undefined ? this.props.rowHeight - this.getFrameBorder() * 2 : PhotoGrid.DEFAULT_EXPECTED_ROW_HEIGHT - this.getFrameBorder() * 2
    };

    static getImagesFromData = (props) => {
        let data = props.data;
        let images = [];
        if(
            data &&
            data.appPhotoGridImages &&
            data.appPhotoGridImages.edges instanceof Array
        ) {
            images = data.appPhotoGridImages.edges/*.sort((a,b) => {
                if(props.spotlightMode === true)
                    return a.node.fields.data.spotlightOrder - b.node.fields.data.spotlightOrder;
                else return 0;
                })
                */.map((imageData) => {
                return {
                    data: imageData.node.fields.data,
                    width: imageData.node.original.width,
                    height: imageData.node.original.height,
                    base64: imageData.node.fluid.base64,
                    aspectRatio: imageData.node.fluid.aspectRatio,
                    src: imageData.node.fluid.src,
                    srcSet: imageData.node.fluid.srcSet,
                    srcSetWebp: imageData.node.fluid.srcSetWebp,
                    sizes: imageData.node.fluid.sizes,
                    originalImg: imageData.node.fluid.originalImg,
                    originalName: imageData.node.fluid.originalName
                }
            });

            /*
            if(category !== undefined) {
                //let categoryRegexp = new RegExp(".+\/src\/pictures\/" + category + "\/.+\.jpg absPath of file >> ImageSharp","i");
                images = images
                    .filter((image) => {
                        return (image.data.type === type && image.data.category === category);
                    });
            }
            */
        }

        return images;
    };

    getAdjustedRetinaImagesSrcSet = (originalSrcSet, pixelDensity=1) => {
        const regex = /^(\/.+.(?:webp|jpg)) ([0-9]+)w/gm;
                
        let matches;
        let modifiedParts = [];
        while((matches = regex.exec(originalSrcSet)) !== null) {
            modifiedParts.push(`${matches[1]} ${matches[2] * pixelDensity}w`);
        }
        return modifiedParts.join(",\n")
    }

    componentDidMount() {
        const images = document.querySelectorAll(`.${styles.imageFrameContainer}`);

        const preloadImage = (imageContainer) => {
            let sourceWebpRetina = imageContainer.childNodes[1].childNodes[0];
            let sourceJpegRetina = imageContainer.childNodes[1].childNodes[1];
            let sourceWebpNormal = imageContainer.childNodes[1].childNodes[2];
            let sourceJpegNormal = imageContainer.childNodes[1].childNodes[3];
            let image  = imageContainer.childNodes[1].childNodes[4];
            sourceWebpRetina.srcset = sourceWebpRetina.dataset.srcset;
            sourceJpegRetina.srcset = sourceJpegRetina.dataset.srcset;
            sourceWebpNormal.srcset = sourceWebpNormal.dataset.srcset;
            sourceJpegNormal.srcset = sourceJpegNormal.dataset.srcset;
            image.src = image.dataset.src;
            image.style.display = "block";
        };

        if(typeof IntersectionObserver !== "undefined") {
            setTimeout(() => {
                const config = {
                    // If the image gets within 50px in the Y axis, start the download.
                    rootMargin: '50% 0%',
                    threshold: 0.01
                };

                const onIntersection = (entries) => {
                    entries.forEach((entry) => {
                        if (entry.intersectionRatio > 0) {
                            observer.unobserve(entry.target);
                            preloadImage(entry.target);
                        }
                    });
                };

                let observer = new IntersectionObserver(onIntersection, config);

                images.forEach(image => {
                    observer.observe(image);
                });
            },1);
        } else {
            images.forEach(image => {
                preloadImage(image);
            });
        }
    }

    render() {
        let images = PhotoGrid.getImagesFromData(this.props);
        let imageIndex=0;
        return (
            <section className={styles.photoGrid}>
                {images.map((image) => {
                    imageIndex++;
                    const imageAlt = `${image.data.shortName ? image.data.shortName + " " : ""}${image.data.locationName ? image.data.locationName + " ": ""}${image.data.date ? image.data.date + " " : ""} `;
                    return (
                        <div className={styles.imageFrameContainer} key={`picture_${imageIndex}`} style={
                            {
                                //width: `${(image.width*EXPECTED_ROW_HEIGHT/image.height) + (this.getFrameBorder() * 2)}px`,
                                width: `${(image.width*this.getExpectedRowHeight()/image.height)}px`,
                                flexGrow:image.width*this.getExpectedRowHeight()/image.height,
                                borderWidth: this.getFrameBorder(),
                                backgroundImage: `url(${image.base64})`
                            }
                        }>
                            <i className={styles.expander} style={{paddingBottom: `${image.height/image.width*100}%`}} />
                            
                            <picture>
                                <source media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)" data-srcset={this.getAdjustedRetinaImagesSrcSet(image.srcSetWebp, 2)} sizes={image.sizes} type={"image/webp"}/>
                                <source media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)" data-srcset={this.getAdjustedRetinaImagesSrcSet(image.srcSet, 2)} sizes={image.sizes} type={"image/jpeg"}/>
                                
                                <source data-srcset={image.srcSetWebp} sizes={image.sizes} type={"image/webp"}/>
                                <source data-srcset={image.srcSet} sizes={image.sizes} type={"image/jpeg"}/>
                                
                                <img className={styles.image} data-src={image.src} sizes={image.sizes} alt={imageAlt}/>
                            </picture>
                            
                            <PhotoInfo imageData={image.data} imageIndex={imageIndex} linkToCategory={this.shouldLinkToCategory()} linkToImagePage={image.data.categorySlug + image.data.imageFileName + "/"}/>
                        </div>
                    )
                })}
            </section>
        );
    }

}



/*

        appPhotoGridImages: allImageSharp(limit: $maxPictures, filter: {fields: {data: {sourceInstanceName: {eq: "pictures"}, category: {eq: $category}, type: {eq: $type}, inSpotlight: {eq: $onlyInSpotlight}}}}, sort: {fields: [fields___data___order], order: $photoGridSortOrder}) {
            edges {
                node {
                }
            }
        }

 */

export const query = graphql`
    fragment PhotoGrid on ImageSharp {
        fields {
            data {
                type
                category
                categoryGroup
                categorySlug
                categoryName
                imageFileName
                imageFileType
                order
                shortName
                description
                date
                locationName
                locationLink
                inSpotlight
                isCategoryCover
                isCategoryGroupCover          
                spotlightOrder
                sourceInstanceName
                imageFileName
            }
        }
        fluid(quality: 80) {
            base64
            aspectRatio
            src
            srcSet
            srcSetWebp
            sizes
            originalImg
            originalName
        }
        original {
            width
            height
        }
    }
`;